import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StadiumComponent } from './stadium.component';
import { ZoomerModule } from './_partials/zoomer/zoomer.module';
import { StadiumModule as StadiumPartialsModule } from './_partials/stadium/stadium.module';
import { InfobarModule } from './_partials/infobar/infobar.module';
import { CartModule } from './_partials/cart/cart.module';
import { SectorsListModule } from './_partials/sectors-list/sectors-list.module';

@NgModule({
  declarations: [
    StadiumComponent,
  ],
  imports: [
    CommonModule,
    ZoomerModule,
    StadiumPartialsModule,
    InfobarModule,
    CartModule,
    SectorsListModule,
  ],
  exports : [
    StadiumComponent
  ]
})
export class StadiumModule { }
