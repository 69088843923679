import { Injectable } from '@angular/core';
import Swal
, { SweetAlertPosition } from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  public success(title: string, text?: string, showConfirmButton: boolean = true, position: string = 'center'): void {
    Swal.fire({
      position: position as SweetAlertPosition,
      icon: 'success',
      title: title,
      showConfirmButton: showConfirmButton,
      confirmButtonText: 'Rendben',
      confirmButtonColor: '#3560b4',
      text: text,
      timerProgressBar: !showConfirmButton,
      timer: !showConfirmButton ? 2500 : undefined, 
    })
  }

  public error(title: string = 'Hiba történt!', text?: string, showConfirmButton: boolean = false): void {
    Swal.fire({ 
      icon: 'error',
      title: title,
      showConfirmButton: showConfirmButton,
      confirmButtonText: 'Rendben',
      confirmButtonColor: '#3560b4',
      html: text,
      timerProgressBar: !showConfirmButton,
      timer: !showConfirmButton ? 6500 : undefined, 
    })
  }

  public confirm(
    title: string, 
    confirmFunction: Function, 
    cancelFunction: Function, 
    confirmButtonText: string,
    cancelButtonText: string,
    text?: string,
  ) : void
  {
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmButtonText,
      cancelButtonText : cancelButtonText,
    }).then((result) => {
      if (result.isConfirmed) {
        confirmFunction()
      } else {
        cancelFunction()
      }
    });
  }
}
