import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiRequestService } from 'src/app/services/ApiRequest/api-request.service';
import { CashierOrderService } from 'src/app/services/CashierOrder/cashier-order.service';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { Store } from '@ngrx/store';
import { UserType } from 'src/app/enums/user/userType';
import { AlertService } from 'src/app/services/Alert/alert.service';

@Component({
  selector: 'app-cashier-orders',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './cashier-orders.component.html',
  styleUrls: ['./cashier-orders.component.scss']
})
export class CashierOrdersComponent implements OnInit {
  public orders?: Array<{id: string, created_at: string}>
  public auth: Auth = {} as Auth
  public error?: string
  public paginate?: {
    lastPage : number,
    currentPage: number,
  }

  constructor(
    private _cashierOrderService: CashierOrderService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store,
    private _alertService: AlertService,
  ) {

    _router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.getOrders()
      }
    });
  }

  async ngOnInit()
  {    
    this._store.subscribe((state: any) => {
      this.auth = state.auth
    });
    this.getOrders()

  }

  private async getOrders() {
    if(!this.isCashier()) {
      return ;
    }

    this.orders = undefined
    const res = await this._cashierOrderService.getCashierOrders(this._route.snapshot.queryParamMap.get('oldal') ?? 1)

    if(res.error) {
      this.error = res.error
      this._alertService.error(undefined, res.error)
      return
    }

    this.orders = res.data.data
    this.paginate = {
      lastPage : res.data.last_page,
      currentPage: res.data.current_page,
    }
  }


  isCashier() {
    return this.auth.isUserLoggedIn && this.auth.user.type == UserType.CASHIER
  }

  createRange(number: Number){
    return new Array(number).fill(0)
      .map((n, index) => index + 1);
  }

}
