<ul class="absolute -right-[10px] top-[41px] z-10 bg-[var(--primary-color)] m-0 rounded-b-2xl min-w-[300px] max-w-[400px] shadow-solid">
    <ng-container *ngIf="!isCashier()">
        <li class="px-6 py-3 text-sm font-semibold uppercase"><a routerLink="/jegyeim">Jegyeim</a></li>
        <li class="px-6 py-3 text-sm font-semibold uppercase"><button (click)="invoiceDetails()" class="!uppercase">Számlázási adatok</button></li>
        <li class="px-6 py-3 text-sm font-semibold uppercase"><button (click)="showPasswordResetModal()" class="!uppercase">Jelszómódosítás</button></li>
    </ng-container>
    <ng-container *ngIf="isCashier()">
        <li class="px-6 py-3 text-sm font-semibold uppercase"><a routerLink="/megrendelesek">Megrendelések</a></li>
    </ng-container>
    <li class="px-6 py-3 text-sm font-semibold"><button type="button" class="!uppercase" (click)="logout()">Kijelentkezés</button></li>
    <li class="px-6 py-3 text-sm bg-[var(--red)] rounded-b-2xl font-semibold break-words">{{ auth.user.email }}</li>
</ul>
