import { Component, Input } from '@angular/core';
import { PurchaseType } from 'src/app/enums/stadium/purchase-type';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { SeasonPasses } from 'src/app/interfaces/State/CartItem/season-passes';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';
import { ModalService } from 'src/app/services/Modal/modal.service';

@Component({
  selector: 'app-season-pass',
  templateUrl: './season-pass.component.html',
  styleUrls: ['./season-pass.component.scss']
})
export class SeasonPassComponent {
  @Input() seasonPass: SeasonPasses = {} as SeasonPasses
  @Input() seasonPassSlug: string = ''
  @Input() cart: Cart = {} as Cart
  @Input() key!: number

  public isRequesting: boolean = false

  constructor(
    private _cartService: CartService,
    private _alertService: AlertService,
    private _modalService: ModalService,
  ) {
  }

  /* SHOW PERSONAIL DETAIL EDIT MODAL */
  showPersonalDetailModal() {
    this._modalService.showCartSeasonPassPersonalDetails({
      seasonPassSeatId: this.seasonPass.seat_id, 
      key: this.key,
      seasonPassSlug : this.seasonPassSlug,
    })
  }

  /* SHOW SEASON PASS TICKET TYPE EDIT MODAL */
  showTicketTypeEditModal() {
    this._modalService.showSeasonPassTicketTypeEditModal({seasonPass: this.seasonPass, seasonPassSlug: this.seasonPassSlug})
  }

  /* REMOVE SEASON PASS */
  async removeSeasonPass(seatId: string, seasonPassSlug: string): Promise<void> {
    if (!seatId) return
    this.isRequesting = true
    const res = await this._cartService.removeSeat(seatId, seasonPassSlug, PurchaseType.SEASON_PASS)
    this.isRequesting = false

    /* handle error */
    if (res.error) {
      this._alertService.error('Hiba történt!', res.error.error ?? 'A foglalás törlése sikertelen!')
    }
  }
}
