<div class="bg-[var(--primary-color)] min-h-[calc(100vh-120px)]">
    <div class="container">
        <div class="flex justify-between">
            <section class="mb-5 w-full mt-10">
                <!-- REQUESTING -->
                <ng-container *ngIf="isRequesting">
                    <h1 class="text-white text-center text-[17px] font-black mb-16 mt-10 pl-10">A felhasználói fiók aktiválása folyamatban...</h1>
                </ng-container>

                <!-- VALIDATED -->
                <ng-container *ngIf="validated">
                    <h1 class="text-white text-center text-[17px] font-black mb-2 mt-10 pl-10">Sikeresen aktiváltad a fiókodat! Vásárold meg jegyedet következő mérkőzésünkre!</h1>
                    <div class="text-center">
                        <a class="inline-block text-white mb-16" [routerLink]="'/'">Tovább a <span class="font-black text-[var(--red)]">főoldalra</span>!</a>
                    </div>
                    <app-fixture-list [fixtures]="fixtures.upcomingFixtures" ></app-fixture-list>
                </ng-container>
                
                <!-- ERROR -->
                <ng-container *ngIf="!isRequesting && error">
                    <h1 class="text-white text-center text-[17px] font-black mb-16 mt-10 pl-10" [innerHTML]="error"></h1>
                    <!--
                    <div class="bg-white rounded-[10px] pt-8 px-10 pb-5 w-full max-w-[450px] mx-auto">
                        <app-login-register-content></app-login-register-content>
                    </div>
                -->
                </ng-container>
            </section>
        </div>
    </div>
</div>
