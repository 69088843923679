import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { lastValueFrom, Observable, take } from 'rxjs';
import { select, State, Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { CommonState } from 'src/app/interfaces/State/Common/common-state';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {
  state?: any

  constructor(private _store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) : Observable<HttpEvent<unknown>>{
    request = request.clone({
      setHeaders: {
        Client: environment.environment == 'development' ? environment.client : window.location.hostname.split('.')[0] 
      }
    })
    return next.handle(request);
  }
}
