import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectorsListComponent } from './sectors-list.component';
import { RouterModule } from '@angular/router';
import { XModule } from 'src/app/components/assets/icons/x/x.module';
import { RoundedButtonComponent } from 'src/app/components/form/rounded-button/rounded-button.component';



@NgModule({
  declarations: [SectorsListComponent],
  imports: [
    CommonModule,
    RouterModule,
    XModule,
    RoundedButtonComponent,
  ],
  exports: [
    SectorsListComponent
  ],
})
export class SectorsListModule { }
