<p *ngIf="error && !success" class="text-[var(--red)] text-center mb-8 break-words" [innerHTML]="error"></p>

<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" [ngClass]="{'is-requesting' : isRequesting}" >
    <ng-container *ngIf="!success">
        <div class="mb-5">
            <app-input [customCssClasses]="isFieldInvalidAtAll('email') ? 'border border-[var(--red)]' : ''" placeholder="E-mail cím" (inputValueChangedEvent)="setValue('email', $event)"></app-input>
            <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'required')" errorMsg="Az e-mail cím megadása kötelező!"></app-input-error-msg>
            <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'email')" errorMsg="Az e-mail cím formátuma nem megfelelő!"></app-input-error-msg>
        </div>
        <div >
            <app-button [isProcessing] = "isRequesting">Jelszó emlékeztető küldése</app-button>
        </div>
    </ng-container>
    <p *ngIf="success" class="text-center border-b-[#dbd9d9] border-b pb-4">A jelszó emlékeztető sikeresen elküldve a megadott e-mail címre!</p>   
    <button type="button" *ngIf="!isUserLoggedIn" (click)="showRegisterForm()" class="text-[#888888] mx-auto cursor-pointer mt-5 mb-0 block text-[13px] text-center">Nincs még fiókod? <span class="text-[var(--red)]">Regisztrálj!</span></button>   
    <button type="button" *ngIf="!isUserLoggedIn" (click)="showLogin()" class="text-[#888888] mx-auto cursor-pointer mt-2 mb-0 block text-[13px] text-center">Vissza a <span class="text-[var(--red)]">belépéshez!</span></button>   

</form> 