import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomescreenComponent } from './homescreen.component';
import { LinkButtonModule } from 'src/app/components/common/link-button/link-button.module';
import { ListModule as FixtureListModule} from 'src/app/components/fixture/list/list.module';
import { ListModule as SeasonPassListModule } from 'src/app/components/season-pass/list/list.module';

@NgModule({
  declarations: [HomescreenComponent],
  imports: [
    CommonModule,
    LinkButtonModule,
    FixtureListModule,
    SeasonPassListModule,
  ],
  exports: [HomescreenComponent],
})
export class HomescreenModule { }
