import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { CartComponent } from './pages/cart/cart.component';
import { IndexComponent as FixtureIndexComponent } from './pages/fixture/index/index.component';
import { IndexComponent as SeasonPassIndexComponent } from './pages/season-pass/index/index.component';
import { HomescreenComponent } from './pages/homescreen/homescreen.component';
import { MyTicketsComponent } from './pages/my-tickets/my-tickets.component';
import { OrderResultComponent } from './pages/order-result/order-result.component';
import { StadiumComponent } from './pages/stadium/stadium.component';
import { MySeasonPassesComponent } from './pages/my-season-passes/my-season-passes.component';
import { VerifyComponent } from './pages/auth/verify/verify.component';
import { ResetPasswordComponent } from './layouts/_partials/modals/login-register-modal/_partials/reset-password/reset-password.component';
import { TestComponent } from './pages/test/test.component';
import { OrderInvoiceComponent } from './pages/order-invoice/order-invoice.component';
import { GameComponent } from './pages/game/game.component';
import { ETicketComponent } from './pages/e-ticket/e-ticket.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { CashierOrdersComponent } from './pages/cashier-orders/cashier-orders.component';

const routes: Routes = [
  {
    path: '', component: DefaultComponent, children: [
      { path: '', component: HomescreenComponent },
      { path: 'merkozesek', component: FixtureIndexComponent },
      { path: 'berletek', component: SeasonPassIndexComponent },
      { path: 'merkozes/:id', component: StadiumComponent },
      { path: 'berlet/:id', component: StadiumComponent },
      { path: 'kosar', component: CartComponent },
      { path: 'megrendeles/:id', component: OrderResultComponent },
      { path: 'megrendeles/:id/szamla', component: OrderInvoiceComponent },
      { path: 'jegyeim', component: MyTicketsComponent },
      { path: 'jegyeim/merkozes/:merkozes/jegy/:jegy', component: ETicketComponent },
      { path: 'jegyeim/berlet/:berlet/jegy/:jegy', component: ETicketComponent },
      { path: 'profil/megerosites', component: VerifyComponent },
      { path: 'jelszo-megvaltoztatasa', component: ResetPasswordComponent}, 
      { path: 'games/:slug', component: GameComponent},
      { path: 'aszf', component: TermsComponent },
      { path: 'adatvedelmi-iranyelvek', component: PrivacyComponent },
      { path: 'megrendelesek', component: CashierOrdersComponent },
    ]
  },
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    onSameUrlNavigation: "reload",
    anchorScrolling: "enabled",
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
