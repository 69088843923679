import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonState } from './interfaces/State/Common/common-state';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'csakjegyek.hu';

  public isLandingPage: boolean = false

  constructor(
    private _store: Store,
  ) {
    if(window.location.hostname == 'csakjegyek.hu') {
      this.isLandingPage = true
    }
  }

  ngOnInit(): void {
    if (!environment.production) {
      this._store.subscribe((state) => {
        console.log(state)
      })
    }
  }
}
