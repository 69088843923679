import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginOrRegisterComponent } from './login-or-register.component';

@NgModule({
  declarations: [LoginOrRegisterComponent],
  imports: [
    CommonModule
  ],
  exports : [LoginOrRegisterComponent],
})
export class LoginOrRegisterModule { }
