import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { InputErrorMsgModule } from 'src/app/components/form/error/input-error-msg/input-error-msg.module';
import { InputModule } from 'src/app/components/form/input/input.module';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { ModalService } from 'src/app/services/Modal/modal.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorMsgModule,
    InputModule,
    ButtonModule,
    RouterModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  error: string = ''
  success: boolean = false
  isRequesting: boolean = false
  private formSubmitAttempt: boolean = false;
  @Input() isUserLoggedIn: boolean = false
  @Output() showRegisterFormEvent: EventEmitter<string> = new EventEmitter();
  @Output() showForgotPasswordFormEvent: EventEmitter<string> = new EventEmitter();
  @Output() showLoginFormEvent: EventEmitter<string> = new EventEmitter();

  forgotPasswordForm = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private _modalService: ModalService,   
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
  ) {
  }

  hideModal() {
    this._modalService.hideModal()
  }
  
  setValue(property: string, value: any) {
    this.forgotPasswordForm.get(property)?.setValue(value)
  }

  showForgotPasswordForm() {
    this.showForgotPasswordFormEvent.emit()
  }

  showRegisterForm() {
    this.showRegisterFormEvent.emit();
  }

  showLogin() {
    this.showLoginFormEvent.emit()
  }

  async onSubmit() {
    if(this.isRequesting) return ;
    this.isRequesting = true
    this.formSubmitAttempt = true

    this.error = ""
    if(!this.forgotPasswordForm.valid) {
      this.isRequesting = false
      return ;
    }
  
    const res = await this._authService.forgotPassword(this.email!.value!)

    this.isRequesting = false

    if(res === true) {
      this.reset()
      this.success = true
      return ;
    }
    
    this.success = false
    this.error = res
  }

  reset() {
    this.forgotPasswordForm.reset()
    this.formSubmitAttempt = false
  }

  isFieldInvalid(field: string, errorType: string = '') {
    return !this.forgotPasswordForm.get(field)!.valid && 
    this.formSubmitAttempt &&
    (errorType == '' || this.forgotPasswordForm?.get(field)?.errors?.[errorType])
  }

  isFieldInvalidAtAll(field: string) {
    return !this.forgotPasswordForm.get(field)!.valid && this.formSubmitAttempt
  }

  get email() { return this.forgotPasswordForm.get('email') }

}
