<p class="error"  class="text-[var(--red)] text-center mb-8 break-words" [innerHTML]="error"></p>
<form [formGroup]="registerForm" (ngSubmit)="onSubmit()" [ngClass]="{'is-requesting' : isRequesting}" >
    <div class="mb-5">
        <app-input [customCssClasses]="isFieldInvalidAtAll('last_name') ? 'border border-[var(--red)]' : ''" placeholder="Vezetéknév" (inputValueChangedEvent)="setValue('last_name', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('last_name', 'required')" errorMsg="A vezetéknév megadása kötelező!"></app-input-error-msg>
    </div>
    <div class="mb-5">
        <app-input [customCssClasses]="isFieldInvalidAtAll('first_name') ? 'border border-[var(--red)]' : ''" placeholder="Keresztnév" (inputValueChangedEvent)="setValue('first_name', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('first_name', 'required')" errorMsg="A keresztnév megadása kötelező!"></app-input-error-msg>
    </div>
    <div class="mb-5">
        <app-input [customCssClasses]="isFieldInvalidAtAll('email') ? 'border border-[var(--red)]' : ''" placeholder="E-mail cím" (inputValueChangedEvent)="setValue('email', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'required')" errorMsg="Az e-mail cím megadása kötelező!"></app-input-error-msg>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'email')" errorMsg="Az e-mail cím formátuma nem megfelelő!"></app-input-error-msg>
    </div>
    <div class="mb-5">
        <app-input type="password" [customCssClasses]="isFieldInvalidAtAll('password') ? 'border border-[var(--red)]' : ''" placeholder="Jelszó" (inputValueChangedEvent)="setValue('password', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('password', 'required')" errorMsg="A jelszó megadása kötelező!"></app-input-error-msg>
    </div>
    <div class="mb-5">
        <app-input type="password" [customCssClasses]="isFieldInvalidAtAll('password_confirmation') ? 'border border-[var(--red)]' : ''" placeholder="Jelszó mégegyszer" (inputValueChangedEvent)="setValue('password_confirmation', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('password_confirmation', 'required')" errorMsg="A jelszó megadása kötelező!"></app-input-error-msg>
    </div>
    <div class="mb-5">
        <app-checkbox (inputValueChangedEvent)="setValue('terms', $event)" identifier="terms" label="A regisztráció során elfogadom a <a target='blank' class='underline text-[var(--red)]' href='/aszf'></app-checkbox>Felhasználási feltételeket</a> és az <a class='underline text-[var(--red)]' target='_blank' href='/adatvedelmi-iranyelvek'>Adatvédelmi tájékoztatót</a>."></app-checkbox>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('terms', 'required')" errorMsg="A feltételek elfogadása kötelező!"></app-input-error-msg>
    </div>
    <div>
        <app-button [isProcessing] = "isRequesting">{{!isRequesting ? 'Regisztrálok' : 'Regisztrálok..'}}</app-button>
    </div>
</form> 