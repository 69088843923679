<div class="p-10 drop-shadow-[0_0_25px_rgba(0,0,0,1)] bg-white relative mt-[10vh] rounded-[10px] w-[600px] max-w-[90vw] sm:!mt-[60px] max-h-[84vh]  sm:max-h-[87vh] overflow-y-scroll">
    <button (click)="hideModal()" class="absolute top-[30px] right-[30px]" type="button" id="closeButton" role="button"><app-x></app-x></button>
    <h1 class="text-[15px] font-bold">Számlázási adatok</h1>
    <p *ngIf="error" class="text-[var(--red)] font-bold text-center mb-6 break-words mt-6" [innerHTML]="error"></p>
    <p *ngIf="success" class="text-[var(--green)] font-bold text-center mb-6 break-words mt-6">{{ success }}</p>
    
    <form *ngIf="!loading && !loadingCountries" [formGroup]="invoiceDetailsForm" (ngSubmit)="onSubmit()" class="mt-5" [ngClass]="{'is-requesting' : isRequesting}" >
        <div class="grid grid-cols-2 sm:grid-cols-1 gap-5 mb-5">
            <div *ngFor="let input of baseInvoiceDetails | keyvalue">
                <label class="mb-1 text-xs text-[#a7a7a7]">{{ baseInvoiceDetails[input.key].label }}: <span *ngIf=" baseInvoiceDetails[input.key].required.includes(auth.user.type)">*</span></label>

                <!-- SELECT -->
                <ng-select 
                    class="custom"
                    notFoundText=""
                    *ngIf="isSelect(baseInvoiceDetails[input.key].type) && baseInvoiceDetails[input.key].name == 'country'" 
                    [items]="countries" 
                    #select
                    [virtualScroll]="false"
                    (change)="setValue(baseInvoiceDetails[input.key].name, $event)"
                    [formControlName]="baseInvoiceDetails[input.key].name"
                    bindLabel="label"
                    bindValue="id"
                    [compareWith]="compareCountry"
                ></ng-select>

                <!-- INPUT -->
                <app-input 
                    *ngIf="!isSelect(baseInvoiceDetails[input.key].type)" 
                    [type]="baseInvoiceDetails[input.key].type" 
                    [value]="invoiceDetailsForm.get(baseInvoiceDetails[input.key].name)?.value" 
                    [customCssClasses]="isFieldInvalidAtAll(baseInvoiceDetails[input.key].name) ? 'border border-[var(--red)]' : ''" 
                    [placeholder]="baseInvoiceDetails[input.key].label" 
                    (inputValueChangedEvent)="setValue(baseInvoiceDetails[input.key].name, $event)"
                ></app-input>

                <!-- REQUIRED ERROR TEXT-->
                <app-input-error-msg *ngIf="baseInvoiceDetails[input.key].required" customCssClasses="pl-4" [show]="isFieldInvalid(baseInvoiceDetails[input.key].name, 'required')" [errorMsg]="baseInvoiceDetails[input.key].validation.error_texts['required']"></app-input-error-msg>
                
                <!-- EMAIL ERROR TEXT-->
                <app-input-error-msg customCssClasses="pl-4" [show]="invoiceDetailsForm.get('email') && isFieldInvalid(baseInvoiceDetails[input.key].name, 'email')" [errorMsg]="baseInvoiceDetails[input.key].validation.error_texts['email']"></app-input-error-msg>
            </div>
        </div>
        <app-button [isProcessing]="isRequesting">Mentés</app-button>
    </form>

    <span *ngIf="loading || loadingCountries" class="text-center p-5 w-full block">Betöltés folyamatban...</span>
</div>