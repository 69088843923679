import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PurchaseType } from 'src/app/enums/stadium/purchase-type';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';

@Component({
  selector: 'app-cart-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent {
  @Input() ticket: any 
  @Input() seasonPass: any
  @Input() currentFixtureId: string = ""
  @Input() currentSeasonPassSlug: string = ""
  public isRequesting: boolean = false
  @Output() showTicketEditForm: EventEmitter<any> = new EventEmitter();
  @Output() showSeasonPassEditForm: EventEmitter<any> = new EventEmitter();

  constructor(
    private _cartService: CartService,
    private _alertService: AlertService,
  ) {
   
  }
  
  /*
  * removes the seat from cart
  */
  async removeSeat(seatId: string, fixtureId: string): Promise<void> {
    if (!seatId) return
    this.isRequesting = true
    const res = await this._cartService.removeSeat(seatId, fixtureId, PurchaseType.TICKET)
    this.isRequesting = false

    /* handle error */
    if (res.error) {
      this._alertService.error('Hiba történt!', res.error.error ?? 'A foglalás törlése sikertelen!')
    }
  }

  /*
  * removes the season pass from cart
  */
  async removeSeasonPass(seatId: string, seasonPassSlug: string): Promise<void> {
    if (!seatId) return
    this.isRequesting = true
    const res = await this._cartService.removeSeat(seatId, seasonPassSlug, PurchaseType.SEASON_PASS)
    this.isRequesting = false

    /* handle error */
    if (res.error) {
      this._alertService.error('Hiba történt!', res.error.error ?? 'A foglalás törlése sikertelen!')
    }
  }

  /*
  * show edit ticket type form
  */
  public editTicket() {
      this.showTicketEditForm.emit({ticket: this.ticket})
  }

  /*
  * show edit season pass form
  */
  public editSeasonPass() {
    this.showSeasonPassEditForm.emit({seasonPass: this.seasonPass})
  }
}
