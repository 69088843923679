<footer class="bg-[#051623] py-6">
    <div class="container">
        <section class="flex gap-14 sm:flex-col sm:gap-2 sm:text-center">
            <p class="text-white font-bold text-sm">Csakjegyek.hu © 2024 Minden jog fenntartva</p>
            <a routerLink="/aszf" class="text-white font-bold text-sm">ÁSZF</a>
            <a routerLink="/adatvedelmi-iranyelvek" class="text-white font-bold text-sm">Adatvédelmi Szabályzat</a>
            <a href="mailto:info@csakjegyek.hu" class="text-white font-bold text-sm">Ügyfélszolgálat</a>
            <!--<a routerLink="/kapcsolat" class="text-white font-bold text-sm">Kapcsolat</a>-->
        </section>
    </div>
</footer>
