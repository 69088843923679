import { Component, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { CookieService } from 'src/app/services/Cookie/cookie.service';

@Component({
  selector: 'app-cookiebar',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './cookiebar.component.html',
  styleUrls: ['./cookiebar.component.scss']
})
export class CookiebarComponent {
  @Output() accept = new EventEmitter<void>();

  public acceptCookie()
  {
    this.accept.emit();
  }
}
