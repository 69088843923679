import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterFormComponent } from './register-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputErrorMsgModule } from 'src/app/components/form/error/input-error-msg/input-error-msg.module';
import { InputModule } from 'src/app/components/form/input/input.module';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { CheckboxModule } from 'src/app/components/form/checkbox/checkbox.module';

@NgModule({
  declarations: [RegisterFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorMsgModule,
    InputModule,
    ButtonModule,
    CheckboxModule,
  ],
  exports : [
    RegisterFormComponent
  ],
})
export class RegisterFormModule { }
