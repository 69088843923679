import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { Ticket } from 'src/app/interfaces/State/CartItem/ticket';
import { CartSeasonPass } from 'src/app/interfaces/State/SeasonPass/cartSeasonPass';
import { SectorDetail } from 'src/app/interfaces/State/Stadium/sector-detail';
import { StadiumWithSectorsAndSeats } from 'src/app/interfaces/State/Stadium/stadium-with-sectors-and-seats';
import { CartTicket } from 'src/app/interfaces/State/Ticket/cartTicket';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';

@Component({
  selector: 'app-stadium-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnChanges{
  @Input() cart: Cart = {} as Cart
  @Input() currentFixtureId: string = ""  
  @Input() currentSeasonPassSlug: string = ""  
  @Input() stadiumState: StadiumWithSectorsAndSeats = {} as StadiumWithSectorsAndSeats

  public showEditTicketTypeForm: boolean = false
  public showEditSeasonPassTypeForm: boolean = false
  public myTickets: any 
  public mySeasonPasses: any
  public ticketTypes: {[key:number]: SectorDetail} = {}
  public ticketToEdit: CartTicket = {} as CartTicket
  public seasonPassToEdit: CartSeasonPass = {} as CartSeasonPass
  public isRequesting: number | boolean = false
 
  @Output() editingTicketTypeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() editingSeasonPassTypeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _alertService: AlertService,
    private _cartService: CartService,
    private _cdr: ChangeDetectorRef,
    private _route: ActivatedRoute,
  ) {

  }

  ngOnInit() {
    this.tickets()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tickets()
  }

  public tickets() {
    const tickets = this.cart?.items?.events ?? {}
    const seasonPasses = this.cart?.items?.season_passes ?? {}

    /* if there is no tickets and season passes in the cart */
    if(!Object.keys(tickets).length && !Object.keys(seasonPasses).length ) {
      this.myTickets = []
      this.mySeasonPasses = []
      return ;
    }

    let otherFixturesTickets: Array<CartTicket> = []
    let currentFixturesTickets: Array<CartTicket> = []
    let currentSeasonPasses: Array<CartSeasonPass> = []
    let otherSeasonPasses: Array<CartSeasonPass> = []

    const eventIds = Object.keys(tickets)
    const seasonPassIds = Object.keys(seasonPasses)
 
    /* SEASON PASSES */
    seasonPassIds.map((seasonPassId) => {
      const seasonPass = seasonPasses[seasonPassId]

      const seasonPassesList = { ...seasonPass.season_passes }

      const seasonPassIdsList = Object.keys(seasonPassesList)

      seasonPassIdsList.map((id) => {
        const ticket = seasonPassesList[id]
        if (seasonPass.details.slug == this.currentSeasonPassSlug) {
          const object = {
            seasonPass: seasonPass.details.name,
            seasonPassSlug: seasonPass.details.slug,
            title: ticket.title,
            type: ticket.type,
            typeId: ticket.type_id,
            id: ticket.seat_id,
            sectorId: ticket.sector_id,
            priceText: ticket.price,
            types: ticket.types,
          } as CartSeasonPass

          currentSeasonPasses.push(object)

          //this.updateTicketUnderEdit(ticket.seat_id, object)

        } else {
          const object = {
            seasonPass: seasonPass.details.name,
            seasonPassSlug: seasonPass.details.slug,
            title: ticket.title,
            type: ticket.type,
            id: ticket.seat_id,
            priceText: ticket.price,
            sectorId: 0,
            typeId: ticket.type_id,
            types: ticket.types,
          } as CartSeasonPass
          
          otherSeasonPasses.push(object)

          //this.updateTicketUnderEdit(ticket.seat_id, object)
        }
      })
    })

    /* TICKETS */
    eventIds.map((eventId) => {
      const event = tickets[eventId]

      const eventTickets = { ...event.tickets }

      const ticketIds = Object.keys(eventTickets)

      ticketIds.map((ticketId) => {
        const ticket = eventTickets[ticketId]
        
        if (event.fixture.id == this.currentFixtureId) {
          const object = {
            event: event.fixture.title,
            eventId: event.fixture.id,
            title: ticket.title,
            type: ticket.type,
            typeId: ticket.type_id,
            id: ticket.seat_id,
            sectorId: ticket.sector_id,
            priceText: ticket.price,
            types: ticket.types,
          }

          currentFixturesTickets.push(object)

          this.updateTicketUnderEdit(ticket.seat_id, object)

        } else {
          const object = {
            event: event.fixture.title,
            eventId: event.fixture.id,
            title: ticket.title,
            type: ticket.type,
            id: ticket.seat_id,
            priceText: ticket.price,
            sectorId: 0,
            typeId: ticket.type_id,
            types: ticket.types,
          }
          
          otherFixturesTickets.push(object)

          this.updateTicketUnderEdit(ticket.seat_id, object)
        }

      })
    })
    
    this.myTickets = currentFixturesTickets.concat(otherFixturesTickets);
    this.mySeasonPasses = currentSeasonPasses.concat(otherSeasonPasses)
  }

  /* SHOW TICKET EDIT FORM */
  public showTicketEditForm(data: {ticket: CartTicket}) {
    this.ticketToEdit = data.ticket

    this.ticketTypes = this.ticketToEdit.types ?? this.stadiumState.sectors[data.ticket.sectorId as any].dt

    if(!this.ticketToEdit) {
      this._alertService.error('Hiba történt!', 'A jegy nem megfelelő!')
      return;
    }
    if(!this.ticketTypes) {
      this._alertService.error('Hiba történt!', 'A jegy típusa nem módosítható!')
      return ;
    }
    this.showEditTicketTypeForm = true
    this.editingTicketTypeEventFn(true)
  }

  /* SHOW SEASON PASS EDIT FORM */
  public showSeasonPassEditForm(data: {seasonPass: CartSeasonPass}) {
    
    this.seasonPassToEdit = data.seasonPass
    
    this.ticketTypes = this.seasonPassToEdit.types ?? this.stadiumState.sectors[data.seasonPass.sectorId as any].dt

    if(!this.seasonPassToEdit) {
      this._alertService.error('Hiba történt!', 'A bérlet nem megfelelő!')
      return;
    }
    if(!this.ticketTypes) {
      this._alertService.error('Hiba történt!', 'A bérlet típusa nem módosítható!')
      return ;
    }
    this.showEditSeasonPassTypeForm = true
    this.editingSeasonPassTypeEventFn(true)
  }

  public hideEditTicketTypeForm() {
    this.showEditTicketTypeForm = false
    this.ticketToEdit = {} as CartTicket
    this.ticketTypes = [] as Array<SectorDetail>
    this._cdr.detectChanges()
    this.editingTicketTypeEventFn(false)
  }

  public hideEditSeasonPassTypeForm() {
    this.showEditSeasonPassTypeForm = false
    this.seasonPassToEdit = {} as CartSeasonPass
    this.ticketTypes = [] as Array<SectorDetail>
    this._cdr.detectChanges()
    this.editingSeasonPassTypeEventFn(false)
  }

  /* SELECT / EDIT TICKET TYPE */
  public async selectTicketType(ticketTypeId: number) {
    if(this.isRequesting) return ;

    this.isRequesting = ticketTypeId
    const res = await this._cartService.updateCartTicketType(this.ticketToEdit.id, this.currentFixtureId, ticketTypeId)

    this.isRequesting = false

    if(res.error) {
      this._alertService.error('Hiba történt!', res.error.error ?? res.error ?? 'A módosítás sikertelen!')
    }

    setTimeout(() => {
      this.hideEditTicketTypeForm()
    }, 500)
    
  }

  /* SELECT / EDIT SEASON PASS TYPE */
  public async selectSeasonPassType(ticketTypeId: number) {
    if(this.isRequesting) return ;

    this.isRequesting = ticketTypeId
    const res = await this._cartService.updateSeasonPassTicketType(this.seasonPassToEdit.id, this.currentSeasonPassSlug, ticketTypeId)

    this.isRequesting = false
    if(res.error) {
      this._alertService.error('Hiba történt!', res.error.error ?? res.error ?? 'A módosítás sikertelen!')
    }

    setTimeout(() => {
      this.hideEditSeasonPassTypeForm()
    }, 500)
    
  }
  

  private updateTicketUnderEdit(seatId: string, ticket: CartTicket) {
    if(!Object.keys(this.ticketToEdit).length || this.ticketToEdit.id != seatId) return ;

    this.ticketToEdit = ticket
  }

  public editingTicketTypeEventFn(event: boolean)
  {
    this.editingTicketTypeEvent.emit(event);
  }

  public editingSeasonPassTypeEventFn(event: boolean)
  {
    this.editingSeasonPassTypeEvent.emit(event);
  }

  get queryString() {
    return this._route.snapshot.queryParams
  }
  
}
