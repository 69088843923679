import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setOrderResult } from 'src/app/actions/order.action';
import { ApiRequestService } from '../ApiRequest/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
  ) { }

  public async getOrder(orderId: string) {
    try {
      const res = await this._apiRequestService.getRequest('orders/' + orderId + '/result');
      this._store.dispatch(setOrderResult({orderResult: res.data}))
      return true
    } catch(e: any) {
      return e.error
    }   
  }

  public async getOrderInvoice(orderId: string) {
    try {
      return await this._apiRequestService.getRequest('orders/' + orderId + '/invoice');
    } catch(e: any) {
      return e.error
    }   
  }

  public async downloadMergedTickets(orderId: string) {
    try {
      return await this._apiRequestService.getRequest('orders/' + orderId + '/download-merged-tickets');
    } catch(e: any) {
      return e.error
    }  
  }

  public async sendSuccesfulEmail(orderId: string) {
    try {
      return await this._apiRequestService.postRequest('orders/' + orderId + '/send-successful-email');
    } catch(e: any) {
      return e.error
    }  
  }
  
}
