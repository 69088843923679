import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsHolderComponent } from './tabs-holder.component';



@NgModule({
  declarations: [TabsHolderComponent],
  imports: [
    CommonModule
  ],
  exports: [
    TabsHolderComponent,
  ],
})
export class TabsHolderModule { }
