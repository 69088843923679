import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandingSectorTicketSelectorComponent } from './standing-sector-ticket-selector.component';
import { XModule } from 'src/app/components/assets/icons/x/x.module';
import { RoundedButtonComponent } from 'src/app/components/form/rounded-button/rounded-button.component';



@NgModule({
  declarations: [
    StandingSectorTicketSelectorComponent
  ],
  imports: [
    CommonModule,
    XModule,
    RoundedButtonComponent,
  ],
  exports : [
    StandingSectorTicketSelectorComponent
  ],
})
export class StandingSectorTicketSelectorModule { }
