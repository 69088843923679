<div class="container">

    <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()" [ngClass]="{'is-requesting' : isRequesting}" >
        <div class="flex justify-between mt-14 sm:flex-col sm:gap-4">
            <div class="w-[67%] sm:w-full">
                <div class="flex gap-3 items-center" [ngClass]="{'mb-5' : mapOnly}">
                    <button (click)="navigateBack()" type="button">
                        <app-back-icon *ngIf="mapOnly"/>
                    </button>
                    <h1 class="text-white text-[15px] font-bold mb-5 flex" [ngClass]="{'mb-0' : mapOnly}">Kosár tartalma</h1>
                </div>
                <div *ngIf="!hasCart()"> 
                    <div class="bg-white rounded-[10px] p-5 ">
                        <p class="font-bold mb-2">A kosarad üres!</p>
                        <p *ngIf="!mapOnly">Mérkőzések megtekintéséhez kattints <a class="text-[var(--red)]" routerLink="/merkozesek">ide</a>!</p>
                        <p *ngIf="mapOnly">Mérkőzések megtekintéséhez kattints a jobb felső sarokban található X gombra!</p>
                    </div>
                </div>
                <div *ngIf="hasCart()" >
                    <!-- TICKETS -->
                    <div class="bg-white rounded-[10px] mb-3" *ngFor="let event of cart.items.events | keyvalue ">
                        <div class="justify-between px-10 py-5 grid grid-cols-[75%_25%] sm:flex sm:flex-col-reverse">
                            <!-- MATCH -->
                            <div class="flex justify-between items-center" *ngIf="isFixtureTypes(event.value.fixture, fixtureTypes().MATCH)">
                                <img class=" h-[40px]" [src]="event.value.fixture.home_team.logo" [alt]="event.value.fixture.home_team.name"/>
                                <div class="px-2">
                                    <p class="text-[15px] font-black text-center">{{ event.value.fixture.home_team.name }} - {{ event.value.fixture.away_team.name }} </p>
                                    <p class="text-sm text-center ">{{ event.value.fixture.venue}}</p>
                                </div>
                                <img class="h-[40px]" [src]="event.value.fixture.away_team.logo" [alt]="event.value.fixture.away_team.name"/>
                            </div>
                            <!-- OTHER EVENT -->
                            <div class="flex gap-3 items-center" *ngIf="isFixtureTypes(event.value.fixture, fixtureTypes().OTHER)">
                                <img class=" h-[40px]" *ngIf="event.value.fixture.image" [src]="event.value.fixture.image" [alt]="event.value.fixture.name"/>
                                <div>
                                    <p class="text-[15px] font-black text-left">{{ event.value.fixture.name }} </p>
                                    <p class="text-sm text-left ">{{ event.value.fixture.venue}}</p>
                                </div>
                            </div>
                            <div class="text-right sm:text-center sm:mb-3">
                                <p class="text-[13px]">{{ event.value.fixture.competition }}</p>
                                <p class="text-[13px]">{{ event.value.fixture.formatted_date }}</p>
                            </div>
                        </div>
                        <ul>
                            <app-ticket [key]="i" [cart]="cart" *ngFor="let ticket of event.value.tickets | keyvalue;  let last = last; let i = index" [ticket] = "ticket.value" [fixtureId]="event.value.fixture.id"></app-ticket> 
                        </ul>
                    </div>
                    <!-- TICKETS -->

                    <!-- SEASON PASSES -->
                    <div class="bg-white rounded-[10px] mb-3" *ngFor="let seasonPass of cart.items.season_passes| keyvalue ">
                        <div class="justify-between px-10 py-5 grid grid-cols-[75%_25%] sm:flex sm:flex-col-reverse">
                            <div class="flex justify-between items-center">
                                <div >
                                    <p class="text-[15px] font-black text-left">{{ seasonPass.value.details.name }}</p>
                                    <p class="text-sm text-left">{{ seasonPass.value.details.subtitle}}</p>
                                </div>
                            </div>
                        </div>
                        <ul>
                            <app-season-pass [key]="i"  [cart]="cart" *ngFor="let spass of seasonPass.value.season_passes | keyvalue;s let last = last; let i = index" [seasonPass] = "spass.value" [seasonPassSlug]="seasonPass.value.details.slug"></app-season-pass> 
                        </ul>
                    </div>
                    <!-- SEASON PASSES -->

                    <ng-container *ngIf="cart.need_to_select_payment_provider">
                        <h2 class="text-white text-[15px] font-bold mb-5 mt-5">Fizetési opciók</h2>
                        <div class="bg-white rounded-[10px] p-5">
                            <app-input-error-msg customCssClasses="pl-5 mb-4" [show]="isFieldInvalid('paymentMethod', 'required')" errorMsg="Fizetési mód választása kötelező!"></app-input-error-msg>
                            <div class="flex flex-col gap-5">
                                <div *ngIf="!isCashier()">
                                    <!-- CUSTOMER -->
                                    <app-radiobutton type="radio" [currentValue]="getValue('paymentMethod')" (inputValueChangedEvent)="setValue('paymentMethod', $event)" labelCssClasses="!text-black !text-[13px]" value="simple_pay" identifier="simple_pay" type="radio" label="<a href='http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf' target='_blank'><img width='120' height='27' src='/assets/images/simple_logo.png' title='SimplePay - Online bankkártyás fizetés' alt='SimplePay vásárlói tájékoztató'></a><small>Tudomásul veszem, hogy a Csakjegyek Kft. (1163, Budapest, Cziráki utca 24-32. A1. ép. 2. em. 110. ajtó) adatkezelő által a csakjegyek.hu felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: fizetendő összeg, tranzakció pénzneme, tranzakció azonosítója, felhasználó e-mail címe, megvásárolt termék megnevezése. Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg: https://simplepay.hu/adatkezelesi-tajekoztatok/ </small>"></app-radiobutton>
                                    <!-- CUSTOMER -->
                                </div>
                                <ng-container *ngIf="isCashier()">
                                    <!-- CASHIER -->
                                    <div >
                                        <app-radiobutton type="radio" [currentValue]="getValue('paymentMethod')" (inputValueChangedEvent)="setValue('paymentMethod', $event)" labelCssClasses="!text-black !text-[13px]" value="cash" identifier="cash" type="radio" label="Készpénzes fizetés"></app-radiobutton>
                                    </div>
                                    <div >
                                        <app-radiobutton type="radio" [currentValue]="getValue('paymentMethod')" (inputValueChangedEvent)="setValue('paymentMethod', $event)" labelCssClasses="!text-black !text-[13px]" value="card" identifier="card" type="radio" label="Bankkártyás fizetés"></app-radiobutton>
                                    </div>
                                    <!-- CASHIER -->
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="w-[30%] sm:w-full">
                <div *ngIf="!auth.isUserLoggedIn">
                    <h2 class="text-white text-[15px] font-bold mb-5">Bejelentkezés</h2>
                    <div class="bg-white rounded-[10px] pt-8 px-10 pb-5">
                        <h3 class="font-bold">Úgy látjuk még nem vagy bejelentkezve!</h3>
                        <p class="text-[#888888] text-[13px] mb-7">A fizetéshez kérjük jelentkezz be vagy hozz létre egy felhasználói fiókot.</p>
                        <app-login-register-content></app-login-register-content>
                    </div>
                </div>
                <div *ngIf="auth.isUserLoggedIn && hasCart()">
                    <h2 class="text-white text-[15px] font-bold mb-5">Összesítő</h2>
                    <div class="bg-white rounded-[10px] pt-8 px-10 pb-5" [ngClass]="{'mb-10' : mapOnly}">
                        <p class="font-bold mb-4 flex gap-3 items-center"><app-wallet></app-wallet> {{ auth.user.email }}</p>   
                        <p class="text-[#888888] text-[13px] mb-5 leading-4">A jegyeid fizetést követően a klub hivatalos applikációjában is elérhetőek lesznek!</p>
                        <p class="text-[13px] font-bold flex justify-between mb-5">Fizetendő összeg: <span>{{ cart.total_price }}</span></p>   
                        <p class="text-[13px] font-bold mb-5">Számlázási adatok / <button class="text-[var(--red)]" type="button" (click)="showInvoiceDetailsModal()">Módosítás</button></p>   
                        <p *ngIf="!invoiceDetails" class="text-[#888888] text-[13px] mb-5 leading-4">Kérjük a “Módosítás”-ra kattintva adja meg számlázási adatait a fizetés előtt!</p>   
                        <div *ngIf="invoiceDetails" class="mb-8">
                            <p class="text-[#888888] text-[13px]" *ngFor="let row of invoiceDetailsFormatted">{{ row }}</p>
                        </div>
                        <app-button [isProcessing] = "isRequesting">{{ actionButtonText }}</app-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
