import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MySeasonPassComponent } from './my-season-pass.component';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from '../assets/icons/spinner/spinner.component';
import { ButtonModule } from '../form/button/button.module';

@NgModule({
  declarations: [MySeasonPassComponent],
  imports: [
    CommonModule,
    RouterModule,
    SpinnerComponent,
    ButtonModule,
  ],
  exports : [
    MySeasonPassComponent
  ]
})
export class MySeasonPassModule { }
