<section class="flex flex-col h-full relative from-tablet">
    <!-- TICKETS & SEASON PASSES -->
    <ng-container *ngIf="!showEditTicketTypeForm && !showEditSeasonPassTypeForm">
        <div class="bg-[var(--red)] p-5 flex justify-between items-center">
            <p class="text-white font-black text-sm">Kiválasztott jegyek <span class="hidden sm:inline" *ngIf="myTickets && myTickets.length">({{ myTickets.length }})</span></p>
            <p class="text-white font-black text-sm">{{ cart.total_price }}</p>
        </div>
        <div class="h-[calc(100%-120px)] overflow-scroll">
            <ul>
                <!-- ON TICKET VIEW -->
                <ng-container *ngIf="currentFixtureId">
                    <ng-container *ngFor="let ticket of myTickets" >
                        <app-cart-ticket (showTicketEditForm)="showTicketEditForm($event)" [ticket]="ticket" [currentFixtureId]="currentFixtureId"></app-cart-ticket>
                    </ng-container>
                    <ng-container *ngFor="let seasonPass of mySeasonPasses" >
                        <app-cart-ticket (showSeasonPassEditForm)="showSeasonPassEditForm($event)" [seasonPass]="seasonPass" [currentSeasonPassSlug]="currentSeasonPassSlug"></app-cart-ticket>
                    </ng-container>
                </ng-container>
                <!-- ON TICKET VIEW -->

                <!-- ON SEASON PASS VIEW -->
                <ng-container *ngIf="currentSeasonPassSlug">
                    <ng-container *ngFor="let seasonPass of mySeasonPasses" >
                        <app-cart-ticket (showSeasonPassEditForm)="showSeasonPassEditForm($event)" [seasonPass]="seasonPass" [currentSeasonPassSlug]="currentSeasonPassSlug"></app-cart-ticket>
                    </ng-container>
                    <ng-container *ngFor="let ticket of myTickets" >
                        <app-cart-ticket (showTicketEditForm)="showTicketEditForm($event)" [ticket]="ticket" [currentFixtureId]="currentFixtureId"></app-cart-ticket>
                    </ng-container>
                </ng-container>
                <!-- ON SEASON PASS VIEW -->
            </ul>
        </div>
        <a [routerLink]="'/kosar'" [queryParams]="queryString" class="p-5 block text-center bg-[var(--secondary-color)] hover:bg-[var(--secondary-color-hover)] transition-all text-white font-bold text-sm">Tovább a fizetéshez</a>
    </ng-container>
    <!-- TICKETS & SEASON PASSES -->

    <!-- SHOW EDIT TICKET TYPE FORM -->
    <ng-container *ngIf="showEditTicketTypeForm">
        <button (click)="hideEditTicketTypeForm()" class="absolute top-6 right-3 outline-none appearance-none border-0">
            <app-x stroke="#fff"></app-x>
        </button>
        <div class="bg-[var(--red)] p-5 flex justify-between items-center">
            <p class="text-white font-black text-sm">{{ ticketToEdit.title }}</p>
        </div>
        <div class="h-[calc(100%-93px)] overflow-scroll">
            <ul class="p-5 gap-5 flex flex-col">
                <li *ngFor="let ticketType of ticketTypes | keyvalue" class="flex justify-between items-center">
                    <div>
                        <p class="font-bold text-sm">{{ ticketType.value.name }}</p>
                        <p class="text-sm">{{ ticketType.value.price_text }}</p>
                    </div>
                    <button 
                        type="button" 
                        *ngIf="ticketType.value.id == ticketToEdit.typeId" 
                        (click)="hideEditTicketTypeForm()"
                        class="w-[200px] rounded-[10px] px-6 py-3 bg-[var(--red)] text-white outline-none appearance-none border border-[var(--red)]"
                    >
                    Kiválasztva</button>
                    
                    <button type="button" 
                        [ngClass]="{'opacity-30 cursor-progress':  isRequesting == ticketType.value.id }" 
                        (click)="selectTicketType(ticketType.value.id)" 
                        *ngIf="ticketType.value.id != ticketToEdit.typeId" 
                        class="w-[200px] rounded-[10px] px-6 py-3 bg-white text-[var(--red)] outline-none appearance-none border border-[var(--red)]">
                        <span  *ngIf="isRequesting != ticketType.value.id" >Kiválasztás</span>
                        <div *ngIf="isRequesting == ticketType.value.id" class="h-[24px]">
                            <app-spinner />
                        </div>
                    </button>
                </li>
            </ul>
        </div>
    </ng-container>
    <!-- SHOW EDIT TICKET TYPE FORM -->

    <!-- SHOW EDIT SEASON PASS TICKET TYPE FORM -->
    <ng-container *ngIf="showEditSeasonPassTypeForm">
        <button (click)="hideEditSeasonPassTypeForm()" class="absolute top-6 right-3 outline-none appearance-none border-0">
            <app-x stroke="#fff"></app-x>
        </button>
        <div class="bg-[var(--red)] p-5 flex justify-between items-center">
            <p class="text-white font-black text-sm">{{ seasonPassToEdit.title }}</p>
        </div>
        <div class="h-[calc(100%-93px)] overflow-scroll">
            <ul class="p-5 gap-5 flex flex-col">
                <li *ngFor="let ticketType of ticketTypes | keyvalue" class="flex justify-between items-center">
                    <div>
                        <p class="font-bold text-sm">{{ ticketType.value.name }}</p>
                        <p class="text-sm">{{ ticketType.value.price_text }}</p>
                    </div>
                    <button 
                        type="button"
                        *ngIf="ticketType.value.id == seasonPassToEdit.typeId" 
                        (click)="hideEditSeasonPassTypeForm()"
                        class="w-[200px] rounded-[10px] px-6 py-3 bg-[var(--red)] text-white outline-none appearance-none border border-[var(--red)]">
                    Kiválasztva</button>
                    <button type="button" 
                        [ngClass]="{'opacity-30 cursor-progress':  isRequesting == ticketType.value.id }" 
                        (click)="selectSeasonPassType(ticketType.value.id)" 
                        *ngIf="ticketType.value.id != seasonPassToEdit.typeId" 
                        class="w-[200px] rounded-[10px] px-6 py-3 bg-white text-[var(--red)] outline-none appearance-none border border-[var(--red)]">
                        <span *ngIf="isRequesting != ticketType.value.id" >Kiválasztás</span>
                        <div *ngIf="isRequesting == ticketType.value.id" class="h-[24px]">
                            <app-spinner />
                        </div>
                    </button>
                </li>
            </ul>
        </div>
    </ng-container>
    <!-- SHOW EDIT SEASON PASS TICKET TYPE FORM -->
</section>
<section class="only-mobile">
    <div class="flex justify-between bg-[var(--secondary-color)] p-5 items-center">
        <div class="select-none">
            <p class="text-white font-bold">{{ (myTickets.length ?? 0) + (mySeasonPasses.length ?? 0) }} jegy / bérlet</p>
            <small class="text-white">{{ cart.total_price }}</small>
        </div>
        <a [routerLink]="'/kosar'" [queryParams]="queryString" id="next-btn" class="flex items-center p-5 rounded-xl text-center bg-[var(--red)] transition-all text-white font-bold text-sm">Tovább</a>
    </div>
</section>
