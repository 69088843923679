import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { hideMobileMenu, showMobileMenu } from 'src/app/actions/common.actions';
import { UserType } from 'src/app/enums/user/userType';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { CommonState } from 'src/app/interfaces/State/Common/common-state';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { NavbarService } from 'src/app/services/Navbar/navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit{
  public common: CommonState = {} as CommonState
  public auth: Auth = {} as Auth


  constructor(
    private _router: Router,
    private _store: Store,
    private _navbarService: NavbarService,
    private _authService: AuthService,
    private _modalService: ModalService,
  ) {
    _router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this._navbarService.hideMobileMenu()
      }
     });
  }

  ngOnInit(): void {
    this._store.subscribe((state: any) => {
      this.common = state.common
      this.auth = state.auth

    });
  }

  toggleMenu() {
    if(this.common.showMobileMenu) {  
      this._navbarService.hideMobileMenu()
    } else {
      this._navbarService.showMobileMenu()
    }
  }

  invoiceDetails() {
    this._navbarService.hideMobileMenu()
    this._modalService.showInvoiceModal()
  }

  hideProfileMenu() {
    this._navbarService.hideProfileMenu()
  }

  logout() {
    this._authService.logout()
  }

  isCashier() {
    return this.auth.isUserLoggedIn && this.auth.user.type == UserType.CASHIER
  }

  showResetPasswordModal() {
    this._modalService.showLoginModal()
  }

  isUserLoggedIn() {
    return this.auth.isUserLoggedIn && this.auth.user.type
  }

}
