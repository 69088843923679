import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeasonPassComponent } from './season-pass.component';
import { XModule } from 'src/app/components/assets/icons/x/x.module';



@NgModule({
  declarations: [
    SeasonPassComponent
  ],
  imports: [
    CommonModule,
    XModule,
  ],
  exports: [
    SeasonPassComponent
  ]
})
export class SeasonPassModule { }
