import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditTicketTypeComponent } from './edit-ticket-type.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { InputModule } from 'src/app/components/form/input/input.module';
import { InputErrorMsgModule } from 'src/app/components/form/error/input-error-msg/input-error-msg.module';
import { XModule } from 'src/app/components/assets/icons/x/x.module';
import { SpinnerComponent } from 'src/app/components/assets/icons/spinner/spinner.component';

@NgModule({
  declarations: [EditTicketTypeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputModule,
    InputErrorMsgModule,
    XModule,
    SpinnerComponent,
  ],
  exports: [EditTicketTypeComponent]
})
export class EditTicketTypeModule { }
