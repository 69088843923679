<!-- LOADER -->
<ul id="fixtures" class="bg-white rounded-[20px] overflow-hidden" *ngIf="fixtures == undefined">
    <li *ngFor="let loader of [].constructor(5); let i = index;" >
        <app-skeleton-loader [row]="3.875" />
    </li>
</ul>

<!-- EMPTY STATE -->
<p class="text-white" *ngIf="!fixtures?.length && fixtures != undefined && needEmptyText">Jelenleg nem lehet egyetlen eseményre sem jegyet vásárolni!</p>

<!-- FIXTURES -->
<ul id="fixtures" class="bg-white rounded-[20px] overflow-hidden" *ngIf="fixtures?.length">
    <li class="p-10 grid grid-cols-[20%_50%_20%] sm:flex sm:flex-col gap-[5%]" *ngFor="let fixture of fixtures">
        <div class="flex flex-col justify-center sm:text-center sm:mb-8 sm:flex-row sm:justify-between">
            <p class="text-[13px] font-black uppercase sm:text-left">{{ fixture.competition?.name }}</p>
            <p class="text-[13px] sm:text-right">{{ fixture.date }}</p>
        </div>
        <div class="flex justify-between items-center" *ngIf="isFixtureTypes(fixture, fixtureTypes().MATCH)">
            <img class="mr-auto h-[40px]" [src]="fixture.home_team.logo" [alt]="fixture.home_team.name"/>
            <div>
                <p class="text-[15px] font-black text-center px-2">{{ fixture.home_team.name }} - {{ fixture.away_team.name }} </p>
                <p class="text-[13px] text-center ">{{ fixture.venue }}</p>
            </div>
            <img class="ml-auto h-[40px]" [src]="fixture.away_team.logo" [alt]="fixture.away_team.name"/>
        </div>
        <div class="flex gap-3 items-center" *ngIf="isFixtureTypes(fixture, fixtureTypes().OTHER)">
            <img *ngIf="fixture.image" class="h-[40px]" [src]="fixture.image" [alt]="fixture.name"/>
            <div>
                <p class="text-[15px] font-black text-left">{{ fixture.name }} </p>
                <p class="text-[13px] text-left ">{{ fixture.venue }}</p>
            </div>
        </div>
        <div class="flex end justify-end items-center sm:flex-col sm:mt-8">
            <app-link-button class="sm:w-full" cssClasses="sm:w-full sm:text-center" [link]="'/merkozes/' + fixture.id">JEGYEK</app-link-button>
        </div>
    </li>
</ul>
