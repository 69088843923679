import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode
} from '@angular/common/http';
import { catchError, from, observable, Observable, Observer, of, retry, switchMap, tap, throwError } from 'rxjs';

import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CookieService } from 'src/app/services/Cookie/cookie.service';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { logout } from 'src/app/actions/auth.actions';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartError } from 'src/app/enums/cart/errors';


@Injectable()
export class UnauthenticatedInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _cookieService: CookieService,
    private _authService: AuthService,
    private _store: Store,
    private _alertService: AlertService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): any {
    return next.handle(request).pipe(catchError((err: any) => {
        if(this.isRefreshingTokenRequest(request.url)) {
          return next.handle(request)
        }
        return this.handleError(err, request, next)
      })
    )
  }

  private handleError(err: any, request: HttpRequest<unknown>, next: HttpHandler)
  {
    const retryableStatusCodes = [
      HttpStatusCode.Unauthorized, 
      HttpStatusCode.Forbidden, 
      CartError.NOT_AUTHENTICATED, 
      CartError.NOT_AUTHENTICATED_AND_LOGOUT
    ];

    if(!retryableStatusCodes.includes(err.status)) {
      return throwError(() => err);
    }
    
    const refreshToken = this._cookieService.getCookie('refresh_token')

    if(!refreshToken) {
      if (err instanceof HttpErrorResponse) {
        /* error code: 401 */
        if([HttpStatusCode.Unauthorized].includes(err.status)) {
          this.handleUnauthError(request, next)
          return throwError(() => err);
        }
      }

      return throwError(() => err);
    }

    return from(this._authService.refreshToken(refreshToken)).pipe(
      switchMap(() => {
        // Retry the original request with the new token
        const clonedRequest = this.retryRequest(request)

        return next.handle(clonedRequest);
      }),
      catchError((refreshError: any) => {
        this._cookieService.deleteCookie('refresh_token')
        // Handle token refresh error, redirect to login page, or notify user
        return next.handle(request)
      })
    );
  }

  private async handleUnauthError(request: HttpRequest<any>, next: HttpHandler) {

    this._cookieService.deleteCookie('access_token')
    this._cookieService.deleteCookie('refresh_token')
    this._cookieService.deleteCookie('expires_at')

    this._authService.logout(false)
    this._alertService.error('A munkameneted lejárt!', 'Kérjük jelentkezz be újra!')
  }



  /* clone request and append access token */
  private retryRequest(req: HttpRequest<any>): HttpRequest<any> {
    const token = this._cookieService.getCookie('access_token')

    if (!token) {
      return req
    }

    return req.clone({
      setHeaders: {
        "Authorization": `Bearer ${token}`,
      }
    })
  }

  private isRefreshingTokenRequest(url: string): Boolean {
    return url.indexOf('/me/refresh') !== -1
  }

}
