<div class="container">
    <div class="bg-white rounded-[10px] p-4 mt-8">
        <h1 class="font-black text-2xl mb-4">Megrendelések</h1>

        <!-- ERRORS -->
         <p *ngIf="error" class="font-bold text-red-700" [innerHTML]="error"></p>
        <p *ngIf="orders == undefined && !error">A megrendelések betöltése folyamatban...</p>
        <p *ngIf="orders != undefined && !orders?.length && (paginate?.currentPage && paginate?.currentPage! == 1)">Nem található megrendelés!</p>

        <p *ngIf="orders != undefined && !orders?.length && (paginate?.currentPage && paginate?.currentPage! > 1)">Nem található megrendelés ezen az oldalon!</p>

        <!-- ORDERS -->
        <div  *ngIf="orders?.length">
            <ul class="flex flex-col gap-2">
                <li *ngFor="let order of orders;let i = index;">
                    <a class="hover:font-bold" [routerLink]="'/megrendeles/' + order.id">{{ order.id }} - {{ order.created_at }}</a>
                </li>
            </ul>
        </div>

        <!-- PAGINATION -->
        <div class="mt-8" *ngIf="orders != undefined">
            <hr/>
            <ul class="text-center mt-4">
                <li *ngFor="let item of createRange(paginate?.lastPage!);" 
                    class="inline-block mx-2 mb-2 hover:bg-[var(--red)] hover:text-white hover:font-bold rounded-lg"
                    [ngClass]="{'bg-[var(--red)] text-white font-bold' : paginate?.currentPage == item }"
                >
                    <a class=" px-2 py-1" [routerLink]="'/megrendelesek'" [queryParams]="{oldal: item}">{{ item }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>