import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { Store } from '@ngrx/store';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { OrderService } from 'src/app/services/Order/order.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BackModule } from 'src/app/components/assets/icons/back/back.module';
import { Location } from '@angular/common'

@Component({
  selector: 'app-order-invoice',
  standalone: true,
  imports: [CommonModule, BackModule, RouterModule],
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.scss']
})
export class OrderInvoiceComponent implements OnInit{
  private destroy$ = new Subject<void>();
  public orderId: string
  public url?: string
  public errorMsg: string = ""
  public auth: Auth = {} as Auth
  public interval: any
  public worker?: Worker
  public mapOnly: boolean = false

  constructor(
    private _store: Store,
    private _route: ActivatedRoute,
    private _alertService: AlertService,
    private _router: Router,
    private _orderService: OrderService,
    private sanitizer: DomSanitizer,
    private location: Location,
  ) {
    this.orderId = this._route.snapshot.paramMap.get('id')!
    this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false

  }

  ngOnInit(): void {
    this.init()
    this._router.events.pipe(takeUntil(this.destroy$)).subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        this.init()
      }
    });

    this._store.pipe(takeUntil(this.destroy$)).subscribe((state: any) => {
      this.auth = state.auth
    })
  }


  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  get invoiceUrl() {
    if(!this.url) return null

    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  async init() {
    const res = await this._orderService.getOrderInvoice(this.orderId)

    if (res.success != true) {
      this._alertService.error('Hiba történt', res.error)
      this.errorMsg = res.error 
      return 
    }

    this.url = res.data.url
  }

  get queryString() {
    return this._route.snapshot.queryParams
  }

}
