import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleSeasonTicketComponent } from './single-season-ticket.component';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { LinkButtonModule } from 'src/app/components/common/link-button/link-button.module';

@NgModule({
  declarations: [SingleSeasonTicketComponent],
  imports: [
    CommonModule,
    ButtonModule,
    LinkButtonModule,
  ],
  exports: [SingleSeasonTicketComponent],
})
export class SingleSeasonTicketModule { }
