<div class="min-h-[calc(100vh-170px)]">
    <div class="bg-white p-10 rounded-[5px] max-w-[calc(100%-30px)] w-[500px] mx-auto my-12">
        <h1 *ngIf="!success" class="mx-auto text-center uppercase">
            <span class="font-bold border-b-2 border-b-[var(--red)] text-[var(--red)]">Új jelszó beállítása</span>
        </h1>
        <p *ngIf="error && !success" class=" mt-6  text-[var(--red)] text-center -mb-4 break-words" [innerHTML]="error"></p>
        <p *ngIf="success" class="text-center ">Az új jelszó sikeresen beállítva, mostantól <span (click)="showLoginModal()" class="text-[var(--red)] font-bold cursor-pointer">bejelentkezhet</span> az új jelszavával!</p>
        <form class="mt-12" *ngIf="!success" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" [ngClass]="{'is-requesting' : isRequesting}">
            <div class="mb-5">
                <app-input [customCssClasses]="isFieldInvalidAtAll('email') ? 'border border-[var(--red)]' : ''"
                    placeholder="E-mail cím" (inputValueChangedEvent)="setValue('email', $event)"></app-input>
                <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'required')"
                    errorMsg="Az e-mail cím megadása kötelező!"></app-input-error-msg>
                <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'email')"
                    errorMsg="Az e-mail cím formátuma nem megfelelő!"></app-input-error-msg>
            </div>
            <div class="mb-5">
                <app-input type="password"
                    [customCssClasses]="isFieldInvalidAtAll('password') ? 'border border-[var(--red)]' : ''"
                    placeholder="Jelszó" (inputValueChangedEvent)="setValue('password', $event)"></app-input>
                <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('password', 'required')"
                    errorMsg="A jelszó megadása kötelező!"></app-input-error-msg>
            </div>
            <div class="mb-5">
                <app-input type="password"
                    [customCssClasses]="isFieldInvalidAtAll('password_confirmation') ? 'border border-[var(--red)]' : ''"
                    placeholder="Jelszó ismét" (inputValueChangedEvent)="setValue('password_confirmation', $event)">
                </app-input>
                <app-input-error-msg customCssClasses="pl-4"
                    [show]="isFieldInvalid('password_confirmation', 'required')"
                    errorMsg="A jelszó ismét megadása kötelező!"></app-input-error-msg>
            </div>
            <div>
                <app-button [isProcessing]="isRequesting">
                    {{!isRequesting ? 'Új jelszó beállítása' : 'Új jelszó beállítása..'}}</app-button>
            </div>
        </form>
    </div>
</div>