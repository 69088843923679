import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './login-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputErrorMsgModule } from 'src/app/components/form/error/input-error-msg/input-error-msg.module';
import { InputModule } from 'src/app/components/form/input/input.module';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LoginFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorMsgModule,
    InputModule,
    ButtonModule,
    RouterModule,
  ],
  exports : [
    LoginFormComponent
  ],
})
export class LoginFormModule { }
