<div *ngIf="isCashier()" class="text-center p-2 bg-yellow-400 font-bold select-none text-[var(--primary-color)] uppercase">
    Pénztáros mód!
</div>
<header class="sm:fixed sm:left-0 sm:top-0 sm:w-full sm:z-10 relative sm:!px-0">
    <div class="container relative">
        <div class="w-full justify-between items-center flex sm:!flex sm:!justify-between">
            <div class="flex justify-between gap-20 sm:!hidden">
                <a routerLink="/" class="flex items-center">
                    <img src="/assets/images/logo.png" class="w-[113px]" alt="Ticketing">
                </a>
                <div>
                    <ul class="gap-10 flex" id="menu">
                        <li class="inline">
                            <a routerLink="/merkozesek" class="uppercase font-bold text-sm">Mérkőzések</a></li>
                        <li class="inline">
                            <a routerLink="/berletek" class="uppercase font-bold text-sm">Bérletek</a></li>
                    </ul>
                </div>
            </div>
            <div class="flex gap-[10px] sm:!hidden items-center">
                <app-login-or-register></app-login-or-register>
                <a routerLink="/kosar"><app-cart-navbar></app-cart-navbar></a>
            </div>
            <div class="hidden sm:!flex sm:!justify-between sm:w-full">
                <div class="flex gap-[15px] justify-between items-center">
                    <app-login-or-register class="sm:hidden"></app-login-or-register>
                    <a routerLink="/kosar"><app-cart-navbar [cartCountdownCounter]="false"></app-cart-navbar></a>
                </div>

                <a routerLink="/" class="flex items-center">
                    <img src="/assets/images/logo.png" class="w-[120px] absolute left-1/2 -translate-x-1/2" alt="Ticketing">
                </a>

                <div class="hamburger hamburger3" (click)="toggleMenu()" [ngClass]="{'opened' : common.showMobileMenu}">
                    <span class="bar1 bar-black"></span>
                    <span class="bar2 bar-black"></span>
                    <span class="bar3 bar-black"></span>
                </div>
            </div>
        </div>
        <app-profile-menu *ngIf="common.showProfileMenu"></app-profile-menu>
    </div>
</header>
<div *ngIf="common.showMobileMenu" class="fixed top-[56px] left-0 w-full h-[calc(100vh-56px)] z-20  backdrop-blur-sm">
    <div class="w-full h-full bg-[var(--primary-color)] pt-10 pb-32 flex flex-col justify-between">
        <ul class="gap-10 flex flex-col mb-12">
            <!-- GUEST -->
            <ng-container>
                <li
                    class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <a routerLink="/merkozesek"
                        class="block uppercase text-xl font-bold text-white text-center">Mérkőzések</a></li>
                <li
                    class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <a routerLink="/berletek"
                        class="block uppercase text-xl font-bold text-white text-center">Bérletek</a></li>

                <li
                    class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <a routerLink="/app" class="block uppercase text-xl font-bold text-white text-center">App</a></li>
            </ng-container>

            <!-- LOGGED IN -->
            <ng-container *ngIf="isUserLoggedIn() && !isCashier()">
                <li  class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <a routerLink="/jegyeim" class="block uppercase text-xl font-bold text-white text-center">Jegyeim</a>
                </li>
                <li class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <button (click)="invoiceDetails()" class="!uppercase block mx-auto text-xl font-bold text-white text-center">Számlázási adatok</button>
                </li>
                <li class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <button (click)="showResetPasswordModal()" class="block uppercase text-xl mx-auto font-bold text-white text-center">Jelszó módosítás</button>
                </li>
            </ng-container>
        </ul>
        <div>
            <ul class="gap-10 flex flex-col">
                <ng-container *ngIf="!isUserLoggedIn()">
                    <li
                        class="relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                        <app-login-or-register></app-login-or-register>
                    </li>
                </ng-container>
                <ng-container *ngIf="isUserLoggedIn()">
                    <li
                        class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                        <button type="button" class="!uppercase block mx-auto text-xl font-bold text-white text-center"
                            (click)="logout()">Kijelentkezés</button>
                    </li>
                </ng-container>

            </ul>
        </div>
    </div>
</div>
