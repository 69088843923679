<div class="container ">
    <div class="rounded-[10px] my-10 px-0 sm:max-w-full max-w-[80%] sm:my-4">
        <ng-container *ngIf="orderResult && orderResult.id && orderResult.success">
            <!-- SUCCESS -->
            <h1 class="text-white font-bold text-lg">Sikeres vásárlás!</h1>
            <p class="text-white" *ngIf="isCustomer()">Mérkőzés jegyeidet / bérleteidet lejjebb görgetve találod meg, illetve később a 
                <ng-container *ngIf="!mapOnly; else spanElement">
                    <a [routerLink]="'/jegyeim'" class="text-[var(--red)]">Jegyeim</a>
                </ng-container>
                <ng-template #spanElement>
                    <span class="text-[var(--red)]">Jegyeim</span>
                </ng-template>
                menüpontban!</p>

            <!-- MERGED TICKETS -->
             <div class="flex justify-between mt-8" *ngIf="isCashier()">
                <div *ngIf="isCashier()">
                    <app-button [style]="' h-full '" 
                        class="font-bold text-[13px] text-[#979797] rounded-[10px] border-[#979797] flex gap-[5px] justify-between items-center" 
                        (click)="printAllTickets()">
                        {{ isPrinting() ? 'Összes jegy nyomtatása..' : 'Összes jegy nyomtatása' }}
                    </app-button>
                </div>

                <div class="inline-block max-w-full" *ngIf="orderResult.invoice_email">
                    <app-button
                    *ngIf="orderResult.has_merged_document"
                    [style]="' h-full '"
                    [isProcessing]="isSendingSuccessfulEmail" 
                    (click)="sendSuccessfulEmail()">
                    Jegyek küldése: {{ orderResult.invoice_email }}</app-button> 
                </div>
            </div>
            <!-- MERGED TICKETS -->

            <!-- FIXTURE -->
            <div class="inline-block max-w-full w-[340px] mt-4" *ngIf="isCashier()">
                <app-button
                    *ngIf="orderResult.has_merged_document"
                    [style]="' h-full '"
                    [isProcessing]="isDownloadingMergedTickets" 
                    (click)="downloadMergedTickets()">
                    Összes jegy/bérlet megnyitása</app-button> 
            
                <div *ngIf="!orderResult.has_merged_document" class="mt-4">
                    <p class="font-black text-white">{{ orderResult.items.document_generation_finished }}/{{ orderResult.items.count }} jegy legenerálva</p>
                    <div class="w-[300px] bg-white h-[10px] rounded-[10px] mt-[10px] relative">
                        <div class="absolute transition-all left-0 top-0 h-[10px] rounded-[8px] bg-[var(--red)] "
                        [style]="'width:' + (orderResult.items.document_generation_finished / orderResult.items.count * 100 ) + '%'"></div>
                    </div>
                </div>
                
            </div>

            <div class="mt-6 flex flex-col gap-8" *ngIf="orderResult.tickets.length">
                <ng-container *ngFor="let fixture of orderResult.tickets">
                   <app-my-ticket 
                        [auth]="auth" 
                        [printTrigger]="remainingTicketCountForPrint$" 
                        [fixtureWrapperObject]="fixture"
                        [isInvoiceIssued]="orderResult?.is_invoice_issued"
                        >
                </app-my-ticket>
               </ng-container>
            </div>

            <!-- SEASON PASS -->
            <div class="mt-6 flex flex-col gap-8" *ngIf="orderResult.season_passes">
                <ng-container *ngFor="let object of orderResult.season_passes| keyvalue">
                   <app-my-season-pass 
                        [auth]="auth" 
                        [seasonPassWrapperObject]="object.value"
                        [isInvoiceIssued]="orderResult?.is_invoice_issued"    
                    >
                    </app-my-season-pass>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="orderResult && orderResult.id && !orderResult.success">
            <h1 class="text-white font-bold text-lg">Sikertelen vásárlás!</h1>
        </ng-container>
        <ng-container *ngIf="orderResult && orderResult.id">
            <div class="bg-white rounded-[10px] p-6 mt-6">
                <h1 class="mb-4">A megrendelés adatai:</h1>
                <p><strong>Összeg</strong>: {{ orderResult.total_price }}</p>
                <p><strong>Választott fizetési mód</strong>: {{ orderResult.payment_provider }}</p>  
                <p><strong>Státusz</strong>: {{ orderResult.status }}</p>
                <p><strong>Azonosító</strong>: {{ orderResult.id }}</p>   
                <div *ngIf="orderResult.text?.length" class="mt-4">
                    <p *ngFor="let text of orderResult.text" [innerHtml] = "text" class="min-h-[24px]"></p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isLoadingInitially">
            <div class="bg-white rounded-[10px] p-6 mt-6">
                <p class="">A megrendelés betöltése folyamatban...</p>
            </div>
        </ng-container>
        <ng-container *ngIf="errorMsg">
            <div class="bg-white rounded-[10px] p-6 mt-6">
                <p class="text-center" [innerHTML]="errorMsg"></p>
            </div>
        </ng-container>
    </div>
</div>