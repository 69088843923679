import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyTicketComponent } from './my-ticket.component';
import { DownloadModule } from '../assets/icons/download/download.module';
import { SpinnerComponent } from '../assets/icons/spinner/spinner.component';
import { TicketModalComponent } from './_partials/ticket-modal/ticket-modal.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../form/button/button.module';



@NgModule({
  declarations: [
    MyTicketComponent
  ],
  imports: [
    CommonModule,
    DownloadModule,
    SpinnerComponent,
    TicketModalComponent,
    RouterModule,
    ButtonModule,
  ],
  exports: [
    MyTicketComponent
  ],
})
export class MyTicketModule { }
