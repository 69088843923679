import { Component, Input } from '@angular/core';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { SectorWithSeats } from 'src/app/interfaces/State/Stadium/sector-with-seats';
import { StadiumWithSectorsAndSeats } from 'src/app/interfaces/State/Stadium/stadium-with-sectors-and-seats';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';

@Component({
  selector: 'app-sectors-list',
  templateUrl: './sectors-list.component.html',
  styleUrls: ['./sectors-list.component.scss']
})
export class SectorsListComponent {
  @Input() cart: Cart = {} as Cart
  @Input() stadiumState: StadiumWithSectorsAndSeats = {} as StadiumWithSectorsAndSeats
  @Input() currentFixtureSlug: string = ""  

  public myTickets: any 
  public requestingSectors: {[key:string]: boolean} = {} 

  constructor(
    private _cartService: CartService,
    private _alertService: AlertService,
  ){

  }

  ngOnInit() {
  }

  get sectors() {
    let array :Array<SectorWithSeats> = []

    if(!this.stadiumState?.sectors) return []
    
    Object.keys(this.stadiumState.sectors).map((value) => {
      array.push(this.stadiumState.sectors[value as any])
    })

    array.sort( (a,b) => a.n > b.n ?1 : -1 );

    return array
  }


  async increaseTicket(sectorId: string) {
    if(this.requestingSectors[sectorId]) return ;

    this.requestingSectors[sectorId] = true
    const res = await this._cartService.bookRandomSeatForSector(sectorId, this.currentFixtureSlug)
    this.requestingSectors[sectorId] = false
    if(res.error) {
      this._alertService.error('Hiba történt!', res.error.error ?? 'A szék befoglalása sikertelen!')
    }
  }
  
  async decreaseTicket(sectorId: string)  {
    if(this.requestingSectors[sectorId]) return ;

    const seat = this.getFirstTicket(sectorId)

    if(!seat) {
      this._alertService.error('Hiba történt!')
      return ;
    }
    
    const seatId = seat.seat_id

    this.requestingSectors[sectorId] = true
    const res = await this._cartService.removeSeat(seatId, this.currentFixtureSlug)
    delete this.requestingSectors[sectorId]

    /* handle error */
    if(res.error) {
      this._alertService.error('Hiba történt!', res.error.error ?? 'A foglalás törlése sikertelen!')
    }
  }

  isRequesting(sectorId: string) {
    return this.requestingSectors[sectorId] === true
  }

  /* get number of tickest for sector */
  getNumberOfTickets(sectorId: string): number {
    const ticketsForCurrentEvent = this.cart?.items?.events?.[this.currentFixtureSlug]?.tickets

    let numberOfTickets = 0

    if(!ticketsForCurrentEvent) return 0

    Object.keys(ticketsForCurrentEvent).map((index: string) => {
      const ticket = ticketsForCurrentEvent[index];
      if(ticket.sector_id == sectorId) {
        numberOfTickets++
      }
    })

    return numberOfTickets 
  }

  /* get first ticket */
  getFirstTicket(sectorId: string) {
    const ticketsForCurrentEvent = this.cart?.items?.events?.[this.currentFixtureSlug]?.tickets
    /* if there is no ticket for event */
    if(!Object.keys(ticketsForCurrentEvent).length) { 
      return false
    }

    let selectedIndex: false | string = false

    /* get first ticket for sector */
    Object.keys(ticketsForCurrentEvent).map((index: string) => {
      const ticket = ticketsForCurrentEvent[index];
      if(ticket.sector_id == sectorId) {
        selectedIndex = index
        return 
      }
    })

    if(!selectedIndex) {
      return false
    }
    
    return ticketsForCurrentEvent[selectedIndex]
  }
}
