<div
    class="p-10 drop-shadow-[0_0_25px_rgba(0,0,0,1)] bg-white relative mt-[10vh] rounded-[10px] w-[600px] max-w-[90vw] sm:!mt-[60px] max-h-[84vh] overflow-y-scroll">
    <button (click)="hideModal()" class="absolute top-[30px] right-[30px]" type="button" id="closeButton" role="button">
        <app-x></app-x>
    </button>
    <h1 class="text-[15px] font-bold" >'{{ sector.n }}' szektor jegyválasztása</h1>
    <p *ngIf="error" class="text-[var(--red)] text-center mb-6 break-words mt-6" [innerHTML]="error"></p>

    <div class="h-[calc(100%-93px)] overflow-scroll">
        <ul>
            <li *ngFor="let ticketType of sector.dt; index as i;" class="flex justify-between p-5 relative border-b border-b-[#d0d0d0]">
                <div>
                    <p class="text-sm font-black">{{ ticketType.name }}</p>
                    <p class="text-sm">{{ ticketType.price_text }}</p>
                </div>
                <div class="flex items-center gap-4" [ngClass]="{'opacity-30 cursor-progress' : isRequesting(ticketType.id)}">
                    <app-rounded-button [disabled]="isRequesting(ticketType.id) || getNumberOfTickets(ticketType.id) == 0" (buttonClicked)="decreaseTicket(ticketType.id)">-</app-rounded-button>
                    <span class="font-bold">{{ getNumberOfTickets(ticketType.id) }}</span>
                    <app-rounded-button [disabled]="isRequesting(ticketType.id)" (buttonClicked)="increaseTicket(ticketType.id)">+</app-rounded-button>
                </div>
            </li>
        </ul>
    </div>
</div>