import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { login, logout, setUser } from 'src/app/actions/auth.actions';
import { setCart } from 'src/app/actions/cart.actions';
import { MeInterface } from 'src/app/interfaces/ApiRequest/me-interface';
import { User } from 'src/app/interfaces/State/User/user';
import { ApiRequestService } from '../ApiRequest/api-request.service';
import { CookieService } from '../Cookie/cookie.service';
import { EncryptDecryptService } from '../EncryptDecryptService/encrypt-decrypt.service';
import { ResetPasswordInterface } from 'src/app/interfaces/ApiRequest/reset-password-interface';
import { LocalPrinter } from 'src/app/interfaces/State/User/local-printer';
import { clearUpcomingMyTickets, setUpcomingMyTickets } from 'src/app/actions/mytickets.actions';
import { clearCurrentMySeasonPasses, setCurrentMySeasonPasses } from 'src/app/actions/myseasonpasses.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
    private _cookieService: CookieService,
    private _encryptDecryptService: EncryptDecryptService,
    private _router: Router,
  ) { }

  public async login(email: string, password: string) {
    try {
      const res = await this._apiRequestService.postRequest('login', { 'email': email, 'password': password, 'scope': 'desktop' })
      this.handleSuccessfulLogin(res);
      return true
    } catch (e: any) {
      return e.error.error;
    }
  }

  private handleSuccessfulLogin(res: any) {

    this._cookieService.setCookie({
      name: 'access_token',
      value: res.data.access_token
    })

    this._cookieService.setCookie({
      name: 'refresh_token',
      value: res.data.refresh_token,
      expireDays: 2,
    })

    this._cookieService.setCookie({
      name: 'expires_at',
      value: Math.floor(Date.now() / 1000) + res.data.expires_in - 60
    })

    this.successfulLogin(res)
  }

  private successfulLogin(res: any) {
    let userData = { email: res.data.email, type: res.data.type, } as User;

    this._store.dispatch(login());

    if (res.data.cart) {
      this._store.dispatch(setCart({ cart: res.data.cart }))
    }

    if(userData.type === 'cashier'){
      userData.printer = res.data.printer as LocalPrinter;
    }

    this._store.dispatch(setUser({ user: userData }))
  }

  public async register(first_name: string, last_name: string, email: string, password: string, password_confirmation: string, terms: boolean) {
    try {
      const res = await this._apiRequestService.postRequest('register', { 
        'first_name': first_name,
        'last_name' : last_name,
        'email': email, 
        'password': password, 
        'password_confirmation': password_confirmation, 
        terms: terms ,
      })
      this.handleSuccessfulLogin(res)

      return true
    } catch (e: any) {
      return e.error.error;
    }
  }

  public async refreshToken(refreshToken: string) {

    if (!refreshToken) return false;

    try {
      const res = await this._apiRequestService.postRequest('me/refresh', { 'refresh_token': refreshToken })
      this.handleSuccessfulLogin(res)

      return true;
    } catch (e: any) {
      return false
    }   
  }

  public async logout(needRequest: boolean = true) {
    if (needRequest) {
      try {
        const res = await this._apiRequestService.postRequest('logout')
      } catch(e: any) {
        return ;
      }
    }

    this._store.dispatch(logout())
    this._cookieService.deleteCookie('access_token')
    this._cookieService.deleteCookie('refresh_token')
    this._cookieService.deleteCookie('expires_at')
    this._store.dispatch(setUser({ user: {} as User }))
    this._store.dispatch(clearUpcomingMyTickets()) 
    this._store.dispatch(clearCurrentMySeasonPasses())
 
    this._router.navigate(['/'])  
  }

  public async setInvoiceDetails(controls: any) {
    let invoiceDetails: { [key: string]: string } = {}

    Object.keys(controls).map((key: string) => {  
      if(controls[key].value != "" && typeof controls[key].value !== 'object' && !Number.isInteger(controls[key].value)) {
        invoiceDetails[key] = this._encryptDecryptService.encrypt(controls[key].value)
      } else if (controls[key].value != "" && typeof controls[key].value === 'object') {
        invoiceDetails[key] = controls[key].value.id
      } else if (controls[key].value != "" && Number.isInteger(controls[key].value)) {
        invoiceDetails[key] = controls[key].value
      }
    })

    try {
      const res = await this._apiRequestService.putRequest('me/invoice-details', invoiceDetails);
      this._store.dispatch(setUser({ user: { ...res.data as User } }))
      return true;
    } catch(e: any) {
      return e.error.error;
    }
  }

  public async getMe(object: MeInterface = {} as MeInterface) {
    let queryString = []
    if (object.details) {
      queryString.push('details=true')
    }
    const res = await this._apiRequestService.getRequest('me?' + queryString.join('&'))
    this._store.dispatch(setUser({ user: { ...res.data as User } }))
  }

  public async validate(token: string) {
    try {
      const res = await this._apiRequestService.getRequest('me/verify?token=' + token)
      return true
    } catch (e: any) {
      return e.error.error.length ? e.error.error : 'Sikertelen aktiválás! Ez az aktiváló link már lejárt vagy hibás!'
    }
  }

  public async forgotPassword(email: string) {
      try {
        const res = await this._apiRequestService.getRequest('me/reset-password?email='  + email)
        return true
      } catch (e: any) {
        return e.error.error;
      }
  }

  public async resetPassword(resetPasswordForm: ResetPasswordInterface) {
    try {
      const res = await this._apiRequestService.postRequest('me/reset-password', { 'email': resetPasswordForm.email, 'password': resetPasswordForm.password, 'password_confirmation': resetPasswordForm.password_confirmation, token: resetPasswordForm.token })
      //this.handleSuccessfulLogin(res)

      return true
    } catch (e: any) {
      return e.error.error;
    }
  }
}
