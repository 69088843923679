import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { State } from 'src/app/interfaces/State/Fixture/state';
import { FixtureService } from 'src/app/services/Fixture/fixture.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ListModule } from 'src/app/components/fixture/list/list.module';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { LoginRegisterContentModule } from 'src/app/layouts/_partials/modals/login-register-modal/_partials/login-register-content/login-register-content.module';

@Component({
  selector: 'app-verify',
  standalone: true,
  imports: [
    CommonModule, 
    ListModule,
    LoginRegisterContentModule,
    RouterModule,
  ],
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent {
  private verificationToken: string = ''
  public isRequesting: boolean = true
  public validated: boolean = false
  public fixtures: State = {
    upcomingFixtures : []
  }
  private _subscription?: Subscription
  public error? : string

  public constructor(
    private _activatedRoute: ActivatedRoute,
    private _fixtureService: FixtureService,
    private _store: Store,
    private _authService: AuthService,
  ) {
   
  }

  ngOnInit() {
    this._fixtureService.getFixtures()

    this._activatedRoute.queryParams
      .subscribe(params => {
        this.verificationToken = params['token']
        this._verifyUser()
      }
    );

    this._subscription = this._store.subscribe((state: any) => {
      this.fixtures = state.fixtures
    })
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }

  async _verifyUser() {
    const res = await this._authService.validate(this.verificationToken)
    this.isRequesting = false

    /* request failed */
    if(res !== true ) {
      this.error = res
      return ;
    }

    /* request success */
    this.validated = true
  }
}
