import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { InputTypes } from 'src/app/enums/form/input/inputTypes';
import { ModalType } from 'src/app/enums/modals/modalType';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { CartRequiredDetail } from 'src/app/interfaces/State/CartRequiredDetails/Partials/CartRequiredDetail/cart-required-detail';
import { UserRequiredDetails } from 'src/app/interfaces/State/User/user-required-details';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { CartService } from 'src/app/services/Cart/cart.service';
import { EncryptDecryptService } from 'src/app/services/EncryptDecryptService/encrypt-decrypt.service';
import { CountryService } from 'src/app/services/Misc/Country/country.service';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';
import { Country } from 'src/app/interfaces/Class/Settings/Country';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit {
  public cart: Cart = {} as Cart
  @Input() public auth: Auth = {} as Auth

  public invoiceDetailsForm = this._formBuilder.group({
  });
  public formSubmitAttempt: boolean = false
  public isRequesting: boolean = false
  public error: string = ""
  public success: string = ""
  private _subscription?: Subscription
  @Input() type!: ModalType
  public baseInvoiceDetails: { [key: string]: CartRequiredDetail } = {}
  public loading: boolean
  public countries: Array<Country> = []
  public loadingCountries: boolean = true
  public defaultCountry: Country = {} as Country

  constructor(
    private _modalService: ModalService,
    private _authService: AuthService,
    private _store: Store,
    private _formBuilder: FormBuilder,
    private _cartService: CartService,
    private _encryptDecryptService: EncryptDecryptService,
    private _alertService: AlertService,
    private _countryService: CountryService,
  ) {
    this.loading = this.isCartInvoiceDetailsType ? false : true
  }

  ngOnInit(): void {
    this.getCountries()

    if (!this.isCartInvoiceDetailsType) {
      this._authService.getMe({ details: true })
    }

    this._subscription = this._store.subscribe((state: any) => {
      this.cart = state.cart
      this.auth = state.auth
      this.setBaseInvoiceDetails()
    })
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }

  defaultBindingsList = [
		{ id: 1, label: 'Vilnius' },
		{ id: 2, label: 'Kaunas' },
		{ id: 3, label: 'Pavilnys' },
    { id: 4, label: 'Vilnius' },
		{ id: 5, label: 'Kaunas' },
		{ id: 6, label: 'Pavilnys' },
    { id: 7, label: 'Vilnius' },
		{ id: 8, label: 'Kaunas' },
		{ id: 9, label: 'Pavilnys' },
    { id: 10, label: 'Vilnius' },
		{ id: 11, label: 'Kaunas' },
		{ id: 12, label: 'Pavilnys' },
	];

  private async getCountries() {
    this.loadingCountries = true
    const response = await this._countryService.getAllCountries()    
    this.countries = [...response.data.countries]

    this.countries.every((country) => {
      if(country.is_default) {
        this.defaultCountry = country
        return false
      }
      return true
    })
    
    if(this.defaultCountry.id && 
      this.invoiceDetailsForm.get('country') && 
      (!this.invoiceDetailsForm.get('country')?.value || this.invoiceDetailsForm.get('country')?.value == "")
    )
    {
      this.setValue('country', this.defaultCountry.id)
    }

    this.loadingCountries = false
  }

  setBaseInvoiceDetails() {
    if (this.isCartInvoiceDetailsType) {
      if(this.cart?.required_details?.invoice_details) {
        this.baseInvoiceDetails = this.cart.required_details.invoice_details
        this.createFormGroup()
      }
    } else {
      if (this.auth.user.required_details) {
        this.baseInvoiceDetails = this.auth.user.required_details.invoice_details
        this.createFormGroup()
      }
    }
  }

  private createFormGroup() {
    let group: any = {}

    Object.keys(this.baseInvoiceDetails).map((key) => {
      const detail = this.baseInvoiceDetails[key]

      if (!detail.show.includes(this.auth.user.type)) {
        return false
      }

      let validationRules = [];

      /* required field */
      if (detail.required.includes(this.auth.user.type)) {
        validationRules.push(Validators.required)
      }

      /* email field */
      if (detail.type == InputTypes.EMAIL) {
        validationRules.push(Validators.email)
      }

      group[detail.name] = new FormControl(
          this.invoiceDetails && 
          this.invoiceDetailsRaw && 
          this.invoiceDetailsRaw[detail.name] ? 
          Number.isInteger(this.invoiceDetailsRaw[detail.name]) ? this.invoiceDetailsRaw[detail.name] : this._encryptDecryptService.decrypt(this.invoiceDetailsRaw[detail.name]) : '', 
          validationRules)
      return true;
    })

    this.invoiceDetailsForm = new FormGroup(group);

    this.loading = false
  }

  get isCartInvoiceDetailsType(): boolean {
    return this.type == ModalType.CART_INVOICE_DETAILS
  }

  async onSubmit() {
    if (this.isRequesting) return;

    this.isRequesting = true
    this.formSubmitAttempt = true

    this.error = ""

    if (!this.invoiceDetailsForm.valid) {
      this.isRequesting = false
      return;
    }

    const res = this.isCartInvoiceDetailsType ?
      await this._cartService.setInvoiceDetails(this.invoiceDetailsForm.controls) :
      await this._authService.setInvoiceDetails(this.invoiceDetailsForm.controls)

    this.isRequesting = false

    if (res === true) {
      this.success = "A számlázási adatok mentése sikeres!"
      setTimeout(() => {
        this._modalService.hideModal(),
          this.success = ""
      }, 1200)

      return;
    }

    this.error = res
  }

  hideModal() {
    /* desktop */
    if (window.outerWidth > 600) {
      this._modalService.hideModal()
      return
    }

    /* mobile */
    this._alertService.confirm(
      "Szeretné menteni a változásokat?",
      () => this.onSubmit(),
      () => this._modalService.hideModal(),
      "Igen",
      "Nem",
    )
  }

  setValue(property: string, value: any) {
    this.invoiceDetailsForm.get(property)?.patchValue(value?.value ?? value)
  }

  isFieldInvalid(field: string, errorType: string = '') {
    if(!this.invoiceDetailsForm.get(field)) {
      return false
    }

    return !this.invoiceDetailsForm.get(field)!.valid &&
      this.formSubmitAttempt &&
      (errorType == '' || this.invoiceDetailsForm?.get(field)?.errors?.[errorType])
  }

  isFieldInvalidAtAll(field: string) {
    if(!this.invoiceDetailsForm.get(field)) {
      return false
    }

    return !this.invoiceDetailsForm.get(field)!.valid && this.formSubmitAttempt
  }

  get invoiceDetails() {
    return this.type == ModalType.CART_INVOICE_DETAILS ? this.cart.details.invoice_details : this.auth.user.details.invoice_details
  }

  get invoiceDetailsRaw() {
    return this.type == ModalType.CART_INVOICE_DETAILS ? this.cart.details.invoice_details?.raw : this.auth.user.details.invoice_details?.raw
  }

  isSelect(inputType: string) {
    return inputType == InputTypes.SELECT
  }

  compareCountry(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2 : c1 === c2;
  }
}
