import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index.component';
import { ListModule as SeasonPassListModule } from 'src/app/components/season-pass/list/list.module';


@NgModule({
  declarations: [
    IndexComponent
  ],
  imports: [
    CommonModule,
    SeasonPassListModule,
  ],
  exports : [
    IndexComponent
  ]
})
export class IndexModule { }
